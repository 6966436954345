export const prettyBytes = (bytes: number) => {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 B';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    if (i >= sizes.length) {
        return 'Unlimited';
    }

    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}

export const getBytesAvailable = (bytesData: AllLimits) => {
    const { bytesLimit, bytesUsed, dayBytesLimit, dayBytesUsed } = bytesData;
    const bytesAvailable = bytesLimit - bytesUsed;
    const dayBytesAvailable = dayBytesLimit - dayBytesUsed;
    return Math.min(bytesAvailable, dayBytesAvailable);
}

export const defaultBytesLimit = 512 * 1024 * 1024;
export const defaultDayBytesLimit = 2 * 1024 * 1024 * 1024;

export const isTokenApplied = (bytesLimit: number, dayBytesLimit: number) => {
    return bytesLimit !== defaultBytesLimit || dayBytesLimit !== defaultDayBytesLimit;
}