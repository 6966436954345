const isDev = process.env.NODE_ENV === 'development';

const host = isDev ?
    `http://${window.location.hostname}:5118`:
    'https://api.tmpfl.xyz';

export const genericRequest = <T>(props : GenericRequestParams) : Promise<ResponsePayload<T>> => {

    const myPromise = new Promise<ResponsePayload<T>>((resolve) => {
        const fetchInit = {
            method: props.method,
            credentials: 'include'
        } as RequestInit;

        if (props.method === 'POST') {
            fetchInit.headers = {
                'Content-Type': 'application/json',
            };
            fetchInit.body = JSON.stringify(props.data);
        }

        const response = fetch(`${host}/${props.route}`, fetchInit);

        response.then((res) => res.json()
            .catch(e => {
                if (e instanceof SyntaxError) {
                    resolve({
                        success: true,
                        data: null as T
                    });
                }
            })
            )
                .then((data) => {
            if (!data.message) {
                resolve({
                    success: true,
                    data: data
                });
            } else {
                resolve({
                    success: false,
                    message: data.message
                });
            }}).catch((err) => {
            resolve({
                success: false,
                message: err.message
            });
        });
    });

    return myPromise;
};

export const getDetails = () => genericRequest<DetailsResponseData>({
        route: 'details',
        method: 'GET'
    });

export const postToken = (payload: TokenPayload) => genericRequest<TokenResponseData>({
        route: 'token',
        method: 'POST',
        data: payload
    });

export const postFiles = (payload: FilesPayload) => genericRequest<FilesResponseData>({
        route: 'files',
        method: 'POST',
        data: payload
    });

export const postUpload = (payload: UploadPayload) => genericRequest<UploadResponseData>({
        route: 'upload',
        method: 'POST',
        data: (() => {
            payload.files = payload.files.map((file) => {
                return {
                    name: file.name,
                    size: file.size
                }
            }) as any;
            return payload;
        })()
    });

export const postRemove = (payload: RemovePayload) => genericRequest<null>({
        route: 'remove',
        method: 'POST',
        data: payload
    });

export const getRemoveToken = () => genericRequest<TokenResponseData>({
        route: 'removetoken',
        method: 'GET'
    });
