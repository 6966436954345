export const ProgressBar = (props: ProgressBarProps) => {
    return (
        <div className='w-full h-4 bg-gray-300 skew-x-12 border-b-2 border-r-2 border-black relative progress-bar overflow-hidden flex'>
            <div className='inner' style={{ width: `${props.progress}%` }}>
                {
                props.progress !== 100 ? <>
                    <div className="ball"></div>
                    <div className="ball"></div>
                </> : <p className="text-xs text-stone-200 opacity-50 text-center w-full">
                    Complete
                </p>
                }
            </div>
            <div className="bg-gray-300 z-10 grow"></div>
        </div>
    );
}

interface ProgressBarProps {
    progress: number;
}