import { useEffect, useState } from "react";
import { getDetails } from "../requests";
import { useSharedData, useSharedDispatch } from "../SharedContext";
import { DataUsed } from "./DataUsed";

export const Limit = () => {

    const sharedDataDispatch = useSharedDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>('8');

    const { bytesUsed, bytesLimit, dayBytesLimit, dayBytesUsed } = useSharedData();

    useEffect(() => {
        getDetails().then((response) => {
            if (response.success) sharedDataDispatch({
                type: 'UPDATE_ALL_LIMITS',
                payload: response.data
            })
            else setError(response.message);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedDataDispatch, window.location.href]);

    return <div className="self-end mt-auto box-border p-6">
        <div className=" flex flex-col gap-4">
            <DataUsed title="Session Limit" n1={bytesUsed} n2={bytesLimit} />
            <DataUsed title="Daily Limit" n1={dayBytesUsed} n2={dayBytesLimit} />
        </div>
    </div>
}