import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Home } from './routes/Home';
import { Nav } from './components/Nav';

function App() {
  const location = useLocation(); 
  const { pathname } = location;

  return (
    <main className='flex flex-col w-screen h-screen'>
      <Nav></Nav>
      {
        pathname === '/' ? 
        <Home /> :
        <Outlet />
      }
    </main>
  );
}

export default App;
