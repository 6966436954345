import { useState } from "react";
import { Link } from "react-router-dom";

export const Nav = () => {

    const [open, setOpen] = useState(false);

    return <>
        { open && <div className="fixed bg-black bg-opacity-50 top-0 left-0 w-screen h-screen z-10">
            <div className="mc relative bg-stone-200 box-border p-4 !max-h-[80vh] flex flex-col">
                <h2 className="font-display flex mb-4">
                    How it works?
                    <span className="inline-block ml-auto cursor-pointer" onClick={() => setOpen(false)}>
                        X
                    </span>
                </h2>
                <div className="overflow-y-auto flex flex-col gap-4">
                    <p>
                        TMPFL.XYZ is a file sharing service that allows you to upload files and quickly and easily share them with others.
                        It requires no registration, it is completely free to use, and URLS are short and easy to remember.
                    </p>
                    <p>
                        All files are encrypted and stored on Google Cloud Storage, and are deleted after 30 minutes.
                    </p>
                    <p>
                        Upon picking the files from your local storage you can optionally set a password and an upload ID.
                        Upload ID is a unique identifier that you can use to access the files later on, you will find it in your URL in the following format: <code>
                            tmpfl.xyz/f/&lt;your-upload-id&gt;
                        </code>.
                    </p>
                    <p>
                        To prevent spam and misuse, there is a limit on how much data you can upload at once, and how much you can upload in a day.
                        The default limit is 512MB per session and 2GB per day. You may contact me at <a 
                            href="mailto:patrikmandic2@gmail.com"
                            className="underline"
                        >patrikmandic2@gmail.com
                        </a> and tell me why you need more space, and you can get a unique token you can apply to increase your limits.
                    </p>
                    <p>
                        If you have any questions or suggestions, feel free to contact me at the email address above.
                    </p>
                </div>
            </div>
        </div> }
        <nav className="flex justify-between p-4 box-border">
            <Link to="/">
                <h1 className="font-display txtsh text-stone-200 text-2xl">TMPFL.XYZ</h1>
            </Link>
            <button className="inline-block underline hover:cursor-pointer" onClick={() => setOpen(true)}>
                How it works
            </button>
        </nav>
    </>
};