export const InputPopup = (props: InputPopupProps) => {

  const { title, placeholder, message, value, setValue, submit, close } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  }

  return (
    <div className="popup">
      <div>
        {close && <button className="absolute top-4 right-12" onClick={close}>X</button>}
        <h2 className="edgy">{title}</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" value={value} placeholder={placeholder} onChange={handleChange}  />
          <button>Submit</button>
        </form>
        <p>{message}</p>
      </div>
    </div>
  );
};

interface InputPopupProps {
  title: string;
  placeholder: string;
  message: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string | null>>;
  submit: () => void;
  close?: () => void;
}