import { useCallback, useState } from "react";
import { useSharedData, useSharedDispatch } from "../SharedContext";
import { isTokenApplied } from "../utils";
import { getRemoveToken, postToken } from "../requests";
import { InputPopup } from "./InputPopup";

export const ApplyToken = () => {

    const [open, setOpen] = useState(false);
    const [token, setToken] = useState<string>("");
    const [msg, setMsg] = useState("Enter your token to increase the amount of data you can upload");

    const sharedDataDispatch = useSharedDispatch();
    const sharedData = useSharedData();

    const tokenApplied = isTokenApplied(sharedData.bytesLimit, sharedData.dayBytesLimit);

    const submit = useCallback(() => {
        if (token === "") {
            setMsg("Token cannot be empty");
            return;
        }
        
        postToken({ token })
            .then(data => {
                if (data.success) {
                    sharedDataDispatch({
                        type: 'UPDATE_ALL_LIMITS',
                        payload: data.data
                    });
                    setOpen(false);
                } else {
                    setMsg(data.message);
                }
            })
    }, [token, sharedDataDispatch]);

    const removeToken = useCallback(() => {
        getRemoveToken()
            .then(data => {
                if (data.success) {
                    sharedDataDispatch({
                        type: 'UPDATE_ALL_LIMITS',
                        payload: data.data
                    });
                } else {
                    setMsg(data.message);
                }
            })
    }, [sharedDataDispatch]);

    const close = useCallback(() => {
        setOpen(false);
        setToken("");
        setMsg("Enter your token to increase the amount of data you can upload");
    }, [setOpen, setToken, setMsg]);

    const handleClick = useCallback(() => {
        if (tokenApplied) {
            removeToken();
        } else {
            setOpen(true);
        }
    }, [tokenApplied, removeToken]);

    return <>
        {
            open && <InputPopup
                title="Apply token"
                placeholder="Enter token"
                message={msg}
                value={token}
                setValue={setToken as any}
                submit={submit}
                close={close}
            />
        }
        <button
            onClick={handleClick}
            className="absolute bottom-2 left-2 underline cursor-pointer"
        >
            {
                tokenApplied ? "Remove token" : "I have a token"
            }
        </button>
    </>
}

