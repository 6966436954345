import { useState } from "react"
import { UploadFiles } from "../components/UploadFiles"
import { UploadProgress } from "../components/UploadProgress";
import { ApplyToken } from "../components/ApplyToken";
import { Limit } from "../components/Limit";

export const Home = () => {

    const [ filesForUpload, setFilesForUpload ] = useState<FileForUpload[]>([]);

    return <>
        {
            !filesForUpload.length ?
            <UploadFiles setFilesForUpload={setFilesForUpload} /> :
            <UploadProgress filesForUpload={filesForUpload} setFilesForUpload={setFilesForUpload} />
        }
        <ApplyToken />
        <Limit />

    </>
}