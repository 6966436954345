import { useCallback, useEffect, useRef, useState } from "react";
import { prettyBytes } from "../utils";
import { Link } from "react-router-dom";
import { ProgressBar } from "./ProgressBar";

const getUploadIdFromUrl = (url: string) => {
    const regex = /tmpfl-xyz-user-files\/(\w+)/gm;
    const match = regex.exec(url);
    return match?.[1];
}

export const UploadProgress = ({ filesForUpload, setFilesForUpload }: UploadProgressProps) => {

    const [error, setError] = useState<string | null>(null);

    const totalSize = filesForUpload.reduce((acc, file) => acc + file.file.size, 0);
    const totalUploaded = filesForUpload.reduce((acc, file) => acc + file.uploaded, 0);
    const uploadId = getUploadIdFromUrl(filesForUpload[0].url);

    const firstRender = useRef(true);

    const uploadToGCS = useCallback(() => {

        filesForUpload.forEach((file) => {

            const updateProgress = (v: number) => {
                setFilesForUpload((prev) => {
                    return prev.map((f) => {
                        if (f.file.name === file.file.name) {
                            return {
                                ...f,
                                uploaded: v
                            }
                        }
                        return f;
                    });
                });
            }
    
            const blob = new Blob([file.file]);
            const xhr = new XMLHttpRequest();
        
            xhr.upload.addEventListener('progress', (e) => {
                if (e.lengthComputable) {
                    updateProgress(e.loaded);
                }
            });
        
            xhr.addEventListener('readystatechange', (e) => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        updateProgress(file.file.size);
                    } else {
                        setError(`Failed to upload ${file.file.name}`);
                    }
                }
            });
        
            xhr.open('PUT', file.url, true);
            xhr.send(blob);
        });
    }, [filesForUpload, setFilesForUpload, setError]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            uploadToGCS();
        }
    }, [uploadToGCS]);

    return <div className="mc">
        {
            totalSize === totalUploaded ?
            <>
                <h2 className="font-display font-bold txtsh mb-4 text-stone-200">Upload complete</h2>
                <p>
                    Your upload is avaliable at <Link 
                        to={`f/${uploadId}`} className="underline">
                        {window.location.origin}/f/{uploadId}
                    </Link> <span 
                        onClick={() => {
                            navigator.clipboard.writeText(`${window.location.origin}/f/${uploadId}`);
                        }}
                        className="text-xs ml-4 text-stone-200 txtsh cursor-pointer">
                        COPY
                    </span>
                </p>
                <button onClick={() => setFilesForUpload([])} className="underline">Upload more</button>
            </> :
            <>
                <ul className="progress-list">
                {
                    filesForUpload.map((file, index) => {
                        return <li key={index}>
                            <span className="max-w-[65%] inline-block break-words">{file.file.name}</span>
                            <div className="lg:w-40 w-16">
                                <ProgressBar progress={file.uploaded / file.file.size * 100} />
                            </div>
                        </li>
                    })
                }
                </ul>
                <div className="separator"></div>
                <ProgressBar progress={totalUploaded / totalSize * 100} />
                <p>
                    Total uploaded: {prettyBytes(totalUploaded)} / {prettyBytes(totalSize)}
                </p>
                {error && <p>{error}</p>}
            </>
        }
    </div>
}

interface UploadProgressProps {
    filesForUpload: FileForUpload[];
    setFilesForUpload: React.Dispatch<React.SetStateAction<FileForUpload[]>>
}
