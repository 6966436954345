import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postFiles, postRemove } from "../requests";
import { InputPopup } from "../components/InputPopup";
import { prettyBytes } from "../utils";

type ComponentStates = 'ok' | 'loading' | '404' | 'passwordRQ' | 'passwordIN' | 'expired';

export const Files = () => {

    const { id } = useParams<{ id: string }>();

    const [files, setFiles] = useState<FilesResponseData>();
    const [password, setPassword] = useState<string | null>(sessionStorage.getItem(`${id}-password`));
    const [state, setState] = useState<ComponentStates>('loading');

    const navigate = useNavigate();

    const getFiles = useCallback(() => {
        if (id == null) return;
        postFiles({
            uploadId: id,
            password
        }).then((response) => {
            if (response.success) {
                setState('ok');
                setFiles(response.data);
                if (password) {
                    sessionStorage.setItem(`${id}-password`, password);
                }

                const timeUntilExpiry = new Date(response.data.expiryTime as string).getTime() - Date.now();

                setTimeout(() => {
                    setState('expired');
                    sessionStorage.removeItem(`${id}-password`);
                }, timeUntilExpiry);
            }
            else { 
                switch (response.message) {
                    case "Upload not found":
                        setState('404');
                        break;
                    case "Password required":
                        setState('passwordRQ');
                        break;
                    case "Invalid password":
                        setState('passwordIN');
                        break;
                    default:
                        console.error(response.message);
                }
            }
        });
    }, [id, password]);

    const downloadAll = useCallback(() => {
        if (files == null) return;
        // use fetch to download all files
        files.files.forEach((file) => {
            fetch(file.url).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = file.name;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        });
    }, [files]);

    const removeUpload = useCallback(() => {
        postRemove({ 
            uploadId: id as string,
            password
        }).then((response) => {
            if (response.success) {
                navigate('/');
            }
            else {
                console.error(response.message);
            }
        });
    }, [id, password, navigate]);

    useEffect(() => {
        getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    return <div className="mc relative">
        {
            state === 'loading' ? <div>Loading...</div> :
            state === '404' ? <div>
                <h2 className="font-display font-bold txtsh mb-4 text-stone-200">Upload not found</h2>
                <p>
                    <Link to="/" className="underline">Homepage</Link>
                </p>
            </div> :
            state.includes("password") ? <InputPopup
                title="Enter password"
                placeholder="Password"
                message={state === 'passwordRQ' ? "This upload is password protected" : "Invalid password"}
                value={password ?? ''}
                setValue={setPassword}
                submit={getFiles}
            ></InputPopup> :
            state === 'expired' ?
            <div>
                <h2 className="font-display font-bold txtsh mb-4 text-stone-200">Upload expired</h2>
                <p className="mb-2">
                    This upload has expired and is no longer avaliable for download.
                </p>
                <p>
                    <Link to="/" className="underline">Homepage</Link>
                </p>
            </div> :
            <>
                <div className="flex justify-between mb-4">
                    <button onClick={removeUpload} className="txtsh text-stone-200 font-bold">Delete Upload</button>
                    <button onClick={downloadAll} className="txtsh text-stone-200 font-bold">Download All</button>
                </div>
                <div className="overflow-y-auto max-h-full flex flex-col gap-3 w-full">
                    {
                        files?.files.map((file, index) =>
                            <div key={index} className="flex justify-between items-center w-full">
                                <div className="flex flex-col break-words max-w-[65%]">
                                    <p className="font-bold">{file.name}</p>
                                    <p>{prettyBytes(file.size)}</p>
                                </div>
                                <a href={file.url} className="mr-4 underline text-xs">Download</a>
                            </div>
                        )
                    }
                </div>
                <div className="absolute w-full">
                    <div className="separator"></div>
                    <p className="text-xs mb-2">
                        <span className="font-bold">Note: </span>
                        Due to browser restrictions, you may need to download files individually. If you are dealing with a lot of data 
                        it is not reccoemnded to download all files at once as it may crash your browser.
                    </p>
                    <p className="text-xs font-bold">
                        Valid until: <span className="font-normal">
                            {new Date(files?.expiryTime as string).toLocaleString().split(',')[1].slice(1)}
                        </span>
                    </p>
                    <p className="text-xs font-bold">
                        Total size: <span className="font-normal">
                            {prettyBytes(files?.files.reduce((c, a) => a.size + c, 0) as number)}
                        </span>
                    </p>
                </div>
            </>
        }
    </div>
};