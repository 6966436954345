import { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";
import { defaultBytesLimit, defaultDayBytesLimit } from "./utils";

export const initialData = {
    bytesUsed: 0,
    dayBytesUsed: 0,
    bytesLimit: defaultBytesLimit,
    dayBytesLimit: defaultDayBytesLimit
};

const SharedContext = createContext<SharedData>(initialData);
const SharedDisaptchContext = createContext<Dispatch<SharedDataAction>>(() => {});

export const SharedDataProvider = ({ children } : { children: ReactNode}) => {

    const [data, dispatch] = useReducer(sharedDataReducer, initialData);
    
    return (
        <SharedContext.Provider value={data}>
            <SharedDisaptchContext.Provider value={dispatch}>
                {children}
            </SharedDisaptchContext.Provider>
        </SharedContext.Provider>
    );
}

const sharedDataReducer = (state: SharedData, action: SharedDataAction) => {
    switch (action.type) {
        case 'UPDATE_ALL_LIMITS':
            return { ...state, ...action.payload };
        case 'UPDATE_USED_LIMITS':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const useSharedData = () => {
    return useContext(SharedContext);
}

export const useSharedDispatch = () => {
    return useContext(SharedDisaptchContext);
}