import { prettyBytes } from "../utils";

export const DataUsed = (props: DataUsedProps) => 
    <div>
        <div className="relative">
            <div className="absolute bg-stone-200 w-full h-full top-0 left-0 z-[-1] skew-x-12 border-b-2 border-r-2 border-black"></div>
            <h2 className="font-display px-4 py-1 font-bold">{props.title}</h2>
        </div>
        <p className="text-right my-1">{prettyBytes(props.n1)} out of {prettyBytes(props.n2)}</p>
            <div className="flex gap-2 justify-end">{
                [...Array(10).keys()].map(i => 
                <div 
                    key={i}
                    className={"h-5 w-3 bg-stone-200 skew-x-12 border-black border-b-2 border-r-2 " 
                        + ((i + 1) > props.n1 / props.n2  * 10 ? "" : "bg-violet-600")
                    }
                >
                </div>
            )}
        </div>
    </div>

interface DataUsedProps {
    title: string;
    n1: number;
    n2: number;
}
