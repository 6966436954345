import { useState } from "react";
import { postUpload } from "../requests";
import { useSharedData, useSharedDispatch } from "../SharedContext";
import { useDropzone } from "react-dropzone";
import { getBytesAvailable, prettyBytes } from "../utils";

export const UploadFiles = ({setFilesForUpload} : UploadFilesProps) => {

    const [files, setFiles] = useState<File[]>([]);
    const dispatch = useSharedDispatch();

    const sharedData = useSharedData();
    const bytesAvailable = getBytesAvailable(sharedData);

    const [error, setError] = useState<string | null>(null);

    const [idAndPass, setIdAndPass] = useState<{uploadId?: string, password?: string}>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIdAndPass({
            ...idAndPass,
            [e.target.name]: e.target.value
        });
    }

    const onDrop = (acceptedFiles: File[]) => {
        const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
        if (totalSize > bytesAvailable) {
            setError("Not enough space available");
            return;
        }
        setFiles(acceptedFiles);
        setError(null);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postUpload({files, ...idAndPass}).then((response) => {
            if (response.success) {
                dispatch({
                    type: "UPDATE_USED_LIMITS",
                    payload: {
                        bytesUsed: response.data.bytesUsed,
                        dayBytesUsed: response.data.dayBytesUsed
                    }
                });
                setFilesForUpload(response.data.signedURLs.map((url, index) => {
                    return {
                        file: files[index],
                        uploaded: 0,
                        url
                    }
                }));
            } else {
                console.error(response.message);
                setError(response.message);
            }
        });
    }

    return <div className="mc">
        {isDragActive && <div className="ol"></div>}
        <form onSubmit={handleSubmit}>
            <div {...getRootProps()} className={"border-black border p-5 text-center flex flex-col justify-center cursor-pointer " 
                + (isDragActive && " dnd-box-active") 
                + (!files.length && " h-48")
                }>
                <input {...getInputProps()}  />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
            {
                !!files.length && <div>
                    <div className="separator"></div>
                    <ul className="file-list">
                        {
                            files.map((file, index) => <li key={index} className="file">
                                <span>{file.name}</span>
                                <span>{prettyBytes(file.size)}</span>
                            </li>)
                        }
                    </ul>
                    <div className="flex flex-col lg:flex-row gap-2 my-2">
                        <input type="text" name="uploadId" value={idAndPass?.uploadId || ""} placeholder="Upload ID (optional)" onChange={handleChange} />
                        <input type="password" name="password" value={idAndPass?.password || ""} placeholder="Password (optional)" onChange={handleChange} />
                    </div>
                    <p>Total size: {prettyBytes(files.reduce((c, a) => a.size + c, 0))}</p>
                    <input type="submit" value="Submit" className="font-display text-stone-200 ml-auto block p-2 txtsh" disabled={!files.length} /> 
                </div>
            }
            {error && <p>{error}</p>}
        </form>
    </div>
};

interface UploadFilesProps {
    setFilesForUpload: React.Dispatch<React.SetStateAction<FileForUpload[]>>
}